@font-face {
  font-family: 'Dosis-Extralight';
  src: url('../Styles/Fonts/Dosis/static/Dosis-ExtraLight.ttf');
}
@font-face {
  font-family: 'Dosis-Variable';
  src: url('../Styles/Fonts/Dosis/Dosis-VariableFont_wght.ttf');
}
.contents{
  font-size: 14pt;
  font-family: 'Dosis-Extralight';
  color: whitesmoke;
    h3{ 
    text-align: justify;
  }
}
.research-contents{
  position: relative;
}
.about-texts{
  display: block;
  justify-content: center;
  align-items: center;
}
#link-to-sites{
  color: whitesmoke;
}
#span1{
  font-style: italic;
}
#span2{
  text-decoration: underline;
}
#span3{
  font-style: italic;
  text-decoration: underline;
}
.imgSrc{
  width: 100%;
}
.img-info{
  left: 0;
  display: flex;
}
.line{
  width: 100%;
  height: 1px;
  background-color: whitesmoke;
}
.credit-block{
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.thanks-block{
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.img-backers{
  display: flex;
}
#imgBacker1{
  width: 10%;
}
#imgBacker2{
  width: 20%;
}
@media screen and (max-width: 1024px){
  #imgBacker1{
    width: 20%;
  }
  #imgBacker2{
    width:30%;
  }
}
/*----------------------------------------------------------------*/
.research-contents{
  font-family: 'Dosis-Extralight';
  color: whitesmoke;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.resImg{
  width: 100%;
}
.fsDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fsbttHolder{
  display: flex;
  justify-content: end;
  width: 100%;
  height: 25px;
}
