@font-face {
  font-family: 'Dosis-Extralight';
  src: url('../Styles/Fonts/Dosis/static/Dosis-ExtraLight.ttf');
}
.navbar{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10vh;
  text-decoration: none;
}
.Logo{
  display: flex;
  justify-content: center;
  align-items: center;
  image-rendering: none;
  width: auto;
  height: auto;
}
.imglogo{
  width: 270px;
  height: 22px;
  cursor: pointer;
  background-image: url("../images/logoRegular.png");
  background-size: cover;
}
.imglogo:hover{
  background-image: url("../images/logoHover.png");
}
.div-bars{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}
.bars{
  display: none;
  cursor: pointer;
  background: none;
  border: none;
  z-index: 99;
}

.menu{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;
  height: 100%;
}
.menuLinks{
  display: flex;
  justify-content:space-around;
  align-items: center;
}
.mobile-menu{
  width: 100vw;
  height: 100vh;
  position:fixed;
  top: 0;
  left: 0;
  background-color: #343434;
  z-index: 99;
}
.mobile-menuLinks{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.linkName{
  font-family: Dosis-Extralight;
  font-size: 18pt;
  color: white;
  margin: 10px;
  text-decoration: none;
}

.linkName:hover{
  color: #FF00FF;
}
.mobile-linkName{
  font-family: Dosis-Extralight;
  font-size: 24pt;
  color: white;
  margin: 10px;
  text-decoration: none;
}
.mobile-linkName:hover{
  color: #FF00FF;
}
@media screen and (max-width: 1024px){
  .menu{
    display: none;
  }
  .bars{
    display: flex;
  }
}