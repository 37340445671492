@font-face {
  font-family: 'Dosis-Extralight';
  src: url('../Styles/Fonts/Dosis/static/Dosis-ExtraLight.ttf');
}
.footer-flexbox{
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /*background-color: rgba(245, 245, 245, 0.355);*/
}
.footer-text{
  text-align: justify;
  font-family:Dosis-Extralight;
  font-weight: lighter;
  color: whitesmoke;
}