@font-face {
  font-family: 'Dosis-Extralight';
  src: url('../Styles/Fonts/Dosis/static/Dosis-ExtraLight.ttf');
}
.regularGrid{
  display: grid;
  height: 100vh;
  grid-template-columns: 0.5fr 4fr 0.5fr;
  grid-template-rows: auto 4fr auto;
  grid-template-areas:
  "empty1 nav empty2"
  "empty1 gridCenter empty2"
  "empty1 footer empty2";
}
nav{
  grid-area: nav;
}
.empty1{
  grid-area: empty1;
}
.gridCenter{
  grid-area: gridCenter;
}
.empty2{
  grid-area: empty2;
}
footer{
  grid-area: footer;
}
