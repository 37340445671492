@font-face {
  font-family: 'Dosis-Extralight';
  src: url('../Styles/Fonts/Dosis/static/Dosis-ExtraLight.ttf');
}
.home-grid{
  display: grid;
  height: 100vh;
  /*background-color: rgb(235, 238, 175);*/
  grid-template-columns: 0.5fr 4fr 0.5fr;
  grid-template-rows: auto 4fr auto;
  grid-template-areas:
  "empty1 nav empty2"
  "empty1 middle empty2"
  "empty1 footer empty2";
}
nav{
  grid-area: nav;
}
.empty1{
  grid-area: empty1;
}
.middle{
  grid-area: middle;
}
.empty2{
  grid-area: empty2;
}
.home-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}
.frame-div{
  position: absolute;
  z-index: -1;
}
.homeFrame{
  border: 0;
  border-radius: 100%;
}
@media screen and (min-width: 1024px){
  .homeFrame{
    width: 750px;
    height: 750px;
  }
}
@media screen and (max-width: 1023px){
  .homeFrame{
    width: 500px;
    height: 500px;
  }
}
@media screen and (max-width: 720px){
  .homeFrame{
    width: 450px;
    height: 450px;
  }
}
/*
.imgBg{
  position: absolute;
  z-index: -1;
  max-width:100%;
}
*/
.question{
  font-family:Dosis-Extralight;
  font-size: 26px;
  text-decoration: none;
  color: whitesmoke;
}
footer{
  grid-area: footer;
}