@font-face {
  font-family: 'Dosis-Extralight';
  src: url('../Styles/Fonts/Dosis/static/Dosis-ExtraLight.ttf');
}
.gameGrid{
  display: grid;
  height: 100vh;
  grid-template-columns: 0.5fr 4fr 0.5fr;
  grid-template-rows: auto 4fr auto;
  grid-template-areas:
  "side1 nav side2"
  "side1 game side2"
  "side1 footer side2";
}
iframe{
  width: 90%;
  height: 100%;
}
nav{
  grid-area: nav;
}
.side1{
  grid-area: side1;
  width: 1fr; 
  height:100vh;
}
.game{
  z-index: 0;
  grid-area: game;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.side2{
  grid-area: side2;
  width: 5vw; 
  height:100vh;
}
footer{
  grid-area: footer;
}
.fsBtt{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding:20px;
  border:0;
  z-index: 1;
  background: none;
}
/*
@media screen and (max-width: 1024px){
  .fsBtt{
    display: none;
  }
}
*/